
import {  ref, onMounted ,reactive, watch,toRefs,defineComponent, computed ,nextTick } from "vue";
import { useRouter  } from 'vue-router'
import { useStore } from 'vuex';
import { getAvaliableDelayProgramsApi,getOfferType,matchingCustomerTypeApi  } from "@/API/claim/delaerClaim/delaer";
//筛选条件类型接口
interface ScreeningConditionsType {
    incentiveCategory: null | string;
    category: null | string;
    incentiveTime: string[];
    programCode: null | string;
    programName: null | string;
}
//表格数据类型
interface TableDataType {
    programId: string;
    programCode: string;
    programNameCn: string;
    programNameEn: string;
    programPeriod: string;
    deadLine: string;
    undeclaredVin: string;
    declaredVin: string;
    status: string;
    category: string;
    periodArray: Array<string>;
    submissionArray: Array<string>;

    programReleaseDate: string;
    programCreator: string;
    delaySubmissionEnd: string;
    
    programBu: string;
    programBuName: string;
    programBuSecond: string;
    programBuSecondName: string;
    customerType: string;
    isDeclare: boolean;
}

interface OfferType {
    id: string;
    pathKey: string;
    nameEn: string;
    nameCn:  string;
}

interface IncentiveCategoryType {
    id: string;
    nameEn: string;
    nameCn: string;
    offerType: Array<OfferType>;
}
export default defineComponent({
    setup() {
        const buList = ref<string[]>([]);
        const searchDom = ref();
        const { state } = useStore();
        const dealerCode = computed(() =>(state.user as any).organization.entityCode)
        // const dealerCode = ref('193120340')
        const dealerNameCN = computed(() =>(state.user as any).organization.entityName)
        const dealerNameEN = computed(() =>(state.user as any).organization.entityNameEn)

        for (let i=0;i<state.user.buList.length;i++){
            buList.value.push(state.user.buList[i].id)
        }
        const router = useRouter();
        //判断左侧菜单是否隐藏

        //筛选条件
        const screeningConditions: ScreeningConditionsType = reactive({
            incentiveCategory: null,
            category: null,
            incentiveTime: [],
            programCode: null,
            programName:null,
        });

        //筛选条件查询参数
        const screeningConditionsParams: ScreeningConditionsType = reactive({
            incentiveCategory: null,
            category: null,
            incentiveTime: [],
            programCode: null,
            programName:null,
        });

        //表格高度
        const tableHeight = ref(window.innerHeight - 400);

        //项目类别
        const incentiveCategoryArray = ref<IncentiveCategoryType[]>([]);
        //项目类别Array
        const offtypeArray = ref<any[]>([]);
        //项目类别是否,不可选择
        const categoryDisable = ref(true);

        const clearConditions = ()=>{
            screeningConditions.incentiveCategory = null;
            screeningConditions.category= null;
            screeningConditions.incentiveTime= [];
            screeningConditions.programCode= null;
            screeningConditions.programName= null;

            screeningConditionsParams.incentiveCategory = null;
            screeningConditionsParams.category= null;
            screeningConditionsParams.incentiveTime= [];
            screeningConditionsParams.programCode= null;
            screeningConditionsParams.programName= null;

            categoryDisable.value = true;
        }

        //表格表头
        const columns = reactive([
            {
                title: "项目编号",
                dataIndex: "programCode",
                key: "programCode",
                width:110,
            },
            {
                title: "项目名称",
                dataIndex: "programNameCn",
                key: "programNameCn",
                width:150,
            },
            {
                title: "项目区间",
                dataIndex: "programPeriod",
                key: "programPeriod",
                width:110,
                slots: { customRender: 'programPeriod' },
            },
            {
                title: "延期项目截止日期",
                dataIndex: "delaySubmissionEnd",
                key: "delaySubmissionEnd",
                width:110,
                // slots: { customRender: 'deadLine' },
            },
            {
                // title: "待申报VIN",
                dataIndex: "undeclaredVin",
                key: "undeclaredVin",
                width:100,
                slots: { title: 'customTitle', customRender: 'undeclaredVin' },
            },
            {
                title: "已申报VIN",
                dataIndex: "declaredVin",
                key: "declaredVin",
                width:100,
            },
            {
                title: "项目状态",
                dataIndex: "status",
                key: "status",
                width:100,
            },
            {
                title: "项目类型",
                dataIndex: "category",
                key: "category",
                width:100,
            },
            { title: '操作',width:100,fixed: 'right', slots: { customRender: 'operation' } },
        ]);
        //表格数据
        const dataSource = ref<TableDataType[]>([]);

        const getParams = ()=>{
            screeningConditionsParams.incentiveCategory = screeningConditions.incentiveCategory;
            screeningConditionsParams.category = screeningConditions.category;
            screeningConditionsParams.incentiveTime = screeningConditions.incentiveTime;
            screeningConditionsParams.programCode = screeningConditions.programCode;
            screeningConditionsParams.programName = screeningConditions.programName;

            let start = "";
            let end = "";
            if (screeningConditionsParams.incentiveTime.length>0){
                start = screeningConditionsParams.incentiveTime[0];
                end = screeningConditionsParams.incentiveTime[1];
            }
            const params = {
                dealerCode:dealerCode.value,
                incentiveCategory:screeningConditionsParams.incentiveCategory || "",
                category:screeningConditionsParams.category|| "",
                begin:start|| "",
                end:end|| "",
                programCode:screeningConditionsParams.programCode|| "",
                programName:screeningConditionsParams.programName|| "",
                // buIdList:buList.value
            }

            return params;
        }


        //项目类别决定项目类型
        const handleChange  = (value: string) => {
            screeningConditions.category = null;
            for (let i=0;i<incentiveCategoryArray.value.length;i++){
                if (incentiveCategoryArray.value[i].nameEn === value){
                    offtypeArray.value = incentiveCategoryArray.value[i].offerType;
                    categoryDisable.value = false;
                }
            }
        }

        //分页
        const pagination = reactive({
            total:0,
            currentPage:1,
            pageSize:20
        })

        const checkDate=(data: any)=>{
            if (data.delaySubmissionEnd.trim() === "") {
                return false
            } 

            if (Date.parse(data.delaySubmissionEnd+' 23:59:59')<new Date().getTime()){
                return false;
            }

            if (data.status === '无需申报'){
                return false;
            }

            return true;
        }

        //获取表格数据
        const getTableDataByCondition =() =>{
            //查询表格
            const params = Object.assign(getParams(),{
                current:pagination.currentPage,
                size:pagination.pageSize
            })
            getAvaliableDelayProgramsApi(params).then((res: any): void => {
                const array = [];
                for (let i=0;i<res.program.length;i++){
                    const submissionSet: Array<string> = [];
                    const myperiodSet: Array<string> = [];
                    const data = res.program[i];
                    data.isDeclare = checkDate(data)
                    if (data.programPeriod){
                        for (let j=0;j<data.programPeriod.length;j++){
                            if (myperiodSet.indexOf(data.programPeriod[j].periodBegin+" - "+ data.programPeriod[j].periodEnd)=== -1){
                                myperiodSet.push(data.programPeriod[j].periodBegin+" - "+ data.programPeriod[j].periodEnd);
                            }
                            // if (submissionSet.indexOf(data.programPeriod[j].submissionEnd)=== -1){
                            //     submissionSet.push(data.programPeriod[j].submissionEnd);
                            // }
                            
                        }
                        data.periodArray = myperiodSet;
                    }

                    data.submissionArray= submissionSet;
                    array.push(data);
                }
                dataSource.value = array;
                console.log(dataSource.value)
                pagination.total = parseInt(res.totalElements);
                
            })
 


        }

        const queryTable = ()=>{
            pagination.currentPage  = 1;
            getTableDataByCondition();
        }

        //更改分页页签
        const pageChange = (page: number)=>{
            pagination.currentPage = page;
            state.selectedRowKeys = [];
            getTableDataByCondition();
        }
        //改变每页大小
        const sizeChange = (page: number,pageSize: number)=>{
            pagination.pageSize = pageSize;
            pagination.currentPage = 1;
            state.selectedRowKeys = [];
            getTableDataByCondition();
        }

        //进行申报
        const operationHandle = (item: TableDataType)=>{
            const param = {
                customerType: item.customerType,
                progDesignId: item.programId,
                programBuId: item.programBu,
                programBuName: item.programBuName,
                programBuSecondId: item.programBuSecond,
                programBuSecondName: item.programBuSecondName,
                programCode: item.programCode,
                programNameCn: item.programNameCn,
                programNameEn: item.programNameEn,
                
            }
            matchingCustomerTypeApi(param).then((res: any): void => {
                console.log('匹配文档')
            })
                router.push({ name: 'DelayDealerProgramCategory', 
                    params: { 
                        activeTab:'0', 

                        // groupId:'99755839-ae4a-439b-ae73-19b80340b0c6',
                        groupId:item.programId,
                        programCode:item.programCode,
                        programNameCn: item.programNameCn,
                        programNameEn: item.programNameEn,
                        declaredVin:item.declaredVin,
                        programOwner:item.programCreator,
                        releaseDate:item.programReleaseDate,
                        
                        programBu: item.programBu,
                        programBuName: item.programBuName,
                        programBuSecond: item.programBuSecond,
                        programBuSecondName: item.programBuSecondName,

                        dealerNameEN:dealerNameEN.value,
                        dealerNameCN:dealerNameCN.value,
                        dealerCode:dealerCode.value,
                        delaySubmissionEnd:item.delaySubmissionEnd
                    } 
            })
        }
        

        

        onMounted(() => {
            
            //获取申报类别，类型
            getOfferType().then((res: any): void => {
                incentiveCategoryArray.value = res.incentiveCategory;
                getTableDataByCondition();
            });
            
            const searchWidth = (searchDom.value as HTMLElement).clientWidth;
            if (searchWidth>1100){
                tableHeight.value = window.innerHeight - 355
            }
            
            // const downloadElement = document.createElement('a')
            // const href = "https://d1031ns3sims0001.s3.cn-north-1.amazonaws.com.cn/watermark/PDF/2021-08-30/d2cf8e44-0ccb-4021-b722-cb96881160b0/Diploma_52bebb29-a431-4162-a876-c1b9850fddf6.pdf?response-content-disposition=attachment%3B%20filename%3DDiploma_52bebb29-a431-4162-a876-c1b9850fddf6.pdf&response-content-type=application%2Fpdf&X-Amz-Security-Token=FwoDYXdzEKr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDPgOaTPYylKE1OD1mSKZA%2FDkgwkAMe4brVU5YHkG3zR%2BbUDITZ2jQ%2BrbWA3fpl8wiahJvyXVDSp7amTnWg%2Bs3y71WwbRkRamQHVf9dsPW4rq5bjxvltyjgWPhH1UiAAtXeb4jZIZ%2BO1VLbC3GB3B2mFwzMpTO54btd3%2FPIicMwavUBJLGlu5puaZc%2BnUEkvKGxXV0pYAm3I82%2Fjdg9khWOtqLxOPnxVQwbiJm5Zebvtx6zACrSfpq8ROKga0LATucjiEOicrYYnQKtVU%2Fn8WLrleNoeEX%2F6hQlA5IMJlgGLhD%2FhrjQmsow4xcNw47wUSzk8t8dHmyxECpuOVyoVQqfvlGRvIZHoTR0GlHdxRWPZutKs8m7Sv%2BnpnBB5hoiuiy4mlFSu6FxJzQ6fP3OQag%2BCYwkiLOrMAMkvS5oWmYasIjiHYWN4AOUpUdouBGgYZ%2FE7o0Nhbrc2EQagouduvQHvB9U9hKk9WI2Ghb8Ply90jE%2Fz%2FTHtj5J6der27pgpajpDQuijEd%2F%2BeJJpbb0sj%2FcdrL5JT3wFyiAVOqBmHf5hgXCbQ2P0BT0woq%2BWwiQYyNaXeKtwArMpCD81tt2u9B%2BovLCNB5l6XcunVIu4OR9xSSF4bHDoxNomFlSjnso7OWDcEVpX6&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210830T021342Z&X-Amz-SignedHeaders=host&X-Amz-Expires=60&X-Amz-Credential=ASIA4ADHRKFNWHYQL7EK%2F20210830%2Fcn-north-1%2Fs3%2Faws4_request&X-Amz-Signature=0fe2700a2736060db4f9a783cb9b0eb6c1d8b526b38c14850ffa866011c8333a"
            // downloadElement.href = href
            // downloadElement.download = 'aaa.pdf'
            // document.body.appendChild(downloadElement)
            // downloadElement.click() //点击下载
            // document.body.removeChild(downloadElement) //下载完成移除元素
            // window.URL.revokeObjectURL(href) //释放掉blob对象

        })

        return {
            queryTable,
            incentiveCategoryArray,offtypeArray,categoryDisable,columns,dataSource,searchDom,
            ...toRefs(screeningConditions),...toRefs(pagination),tableHeight,
            clearConditions,getTableDataByCondition,sizeChange,pageChange,handleChange,operationHandle
        }
    }

})
